
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { signInWithEmailAndPassword, getAuth, signInWithRedirect, OAuthProvider, signInWithPopup } from 'firebase/auth';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit{

  constructor(
    private router: Router,

  ) {

  }

  async ngOnInit() {
    const auth = getAuth();
    
    
  }

  login(username: string, password: string) {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential: any) => {
        // Successfully signed in
        this.router.navigate(['/dashboard']);
        // console.log('User signed in:', userCredential);
      })
      .catch((error) => {
        // Handle errors here
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Error signing in: \n ' + errorCode);
      });
  }

  loginWithMicrosoft() {
    const auth = getAuth();
    const provider = new OAuthProvider('microsoft.com')
    signInWithPopup(auth, provider)
      .then((userCredential: any) => {
        // Successfully signed in
        this.router.navigate(['/dashboard']);
        // console.log('User signed in:', userCredential);
      })
      .catch((error) => {
        // Handle errors here
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('Error signing in: \n ' + errorCode);
      });
  }
}
