import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public loadingText = {
        policy: [
            "INSURER - REGISTERED NAME",
            "INSURER - COMPANY REGISTRATION NUMBER",
            "INSURER - VAT NUMBER",
            "INSURER - FSP NUMBER",
            "INSURER - CONTACT DETAILS: PHYSICAL ADDRESS",
            "INSURER - CONTACT DETAILS: POSTAL ADDRESS",
            "INSURER - CONTACT DETAILS: TELEPHONE NUMBER",
            "INSURER - CONTACT DETAILS: FAX NUMBER",
            "INSURER - CONTACT DETAILS: EMAIL",
            "INSURER - CONTACT DETAILS: WEBSITE",
            "BROKER - REGISTERED NAME",
            "BROKER - COMPANY REGISTRATION NUMBER",
            "BROKER - VAT NUMBER",
            "BROKER - FSP NUMBER",
            "BROKER - CONTACT DETAILS: PHYSICAL ADDRESS",
            "BROKER - CONTACT DETAILS: POSTAL ADDRESS",
            "BROKER - CONTACT DETAILS: TELEPHONE NUMBER",
            "BROKER - CONTACT DETAILS: FAX NUMBER",
            "BROKER - CONTACT DETAILS: EMAIL",
            "BROKER - CONTACT DETAILS: WEBSITE",
            "BROKER - CONTACT PERSON NAME:",
            "BROKER - CONTACT PERSON CELL NO:",
            "BROKER - CONTACT PERSON EMAIL:",
            "INSURED DETAILS - REGISTERED NAME",
            "INSURED DETAILS - COMPANY REGISTRATION NUMBER",
            "INSURED DETAILS - VAT NUMBER",
            "INSURED DETAILS - CONTACT DETAILS: RISK ADDRESS",
            "INSURED DETAILS - CONTACT DETAILS: POSTAL ADDRESS",
            "INSURED DETAILS - CONTACT DETAILS: WORK TELEPHONE NUMBER",
            "INSURED DETAILS - CONTACT DETAILS: HOME TELEPHONE NUMBER",
            "INSURED DETAILS - CONTACT DETAILS: CELL TELEPHONE NUMBER",
            "INSURED DETAILS - CONTACT DETAILS: EMAIL",
            "INSURED DETAILS - FAX NUMBER",
            "INSURED DETAILS - BUSINESS DESCRIPTION",
            "POLICY DETAILS - HOLLARD POLICY NUMBER",
            "POLICY DETAILS - BROKER POLICY NUMBER",
            "POLICY DETAILS - POLICY INCEPTION DATE",
            "POLICY DETAILS - POLICY RENEWAL DATE",
            "POLICY DETAILS - TYPE OF POLICY",
            "POLICY DETAILS - PAYMENT FREQUENCY",
            "POLICY DETAILS - PERIOD OF INSURANCE",
            "POLICY DETAILS - COMMENTS",
        ],
        fire: [
            "FIRE - ITEM DESCRIPTION",
            "FIRE - EFFECTIVE DATE",
            "FIRE - RISK ADDRESS",
            "FIRE - SASRIA COVER (Y/N)",
            "FIRE - CONSTRUCTION ROOF",
            "FIRE - CONSTRUCTION WALL",
            "FIRE - MAJOR COVERS | Buildings/SUM INSURED",
            "FIRE - MAJOR COVERS | Buildings/PREMIUM",
            "FIRE - MAJOR COVERS | Plant and Machinery/SUM INSURED",
            "FIRE - MAJOR COVERS | Plant and Machinery/PREMIUM",
            "FIRE - MAJOR COVERS | Plant and Machinery and Stock/SUM INSURED",
            "FIRE - MAJOR COVERS | Plant and Machinery and Stock/PREMIUM",
            "FIRE - MAJOR COVERS | Miscellaneous Item/DESCRIPTION",
            "FIRE - MAJOR COVERS | Miscellaneous Items/SUM INSURED",
            "FIRE - MAJOR COVERS | Miscellaneous Items/PREMIUM",
            "FIRE - MAJOR COVERS | Rent/SUM INSURED",
            "FIRE - MAJOR COVERS | Rent/PREMIUM",
            "FIRE - MAJOR COVERS | Stock/SUM INSURED",
            "FIRE - MAJOR COVERS | Stock/PREMIUM",
            "FIRE - MAJOR COVERS | STOCK - DECLARATION",
            "FIRE - EXTENTIONS | Additional Claims Preparation Costs | Sum Insured",
            "FIRE - EXTENTIONS | Brands and Labels | Premium",
            "FIRE - EXTENTIONS | Brands and Labels | Sum Insured",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (1ST YEAR INFLATION) | Premium",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (1ST YEAR INFLATION) | Sum Insured",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (2ND YEAR INFLATION) | Premium",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (2ND YEAR INFLATION) | Sum Insured",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (3RD YEAR INFLATION) | Premium",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (3RD YEAR INFLATION) | Sum Insured",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (ESCALATION) | Premium",
            "FIRE - EXTENTIONS | BUILDINGS - ESCALATION/INFLATION (ESCALATION) | Sum Insured",
            "FIRE - EXTENTIONS | Leakage | Premium",
            "FIRE - EXTENTIONS | Leakage | Sum Insured",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (1ST YEAR INFLATION) | Premium",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (1ST YEAR INFLATION) | Sum Insured",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (2ND YEAR INFLATION) | Premium",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (2ND YEAR INFLATION) | Sum Insured",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (3RD YEAR INFLATION) | Premium",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (3RD YEAR INFLATION) | Sum Insured",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (ESCALATION) | Premium",
            "FIRE - EXTENTIONS | PLANT AND MACHINERY - ESCALATION/INFLATION (ESCALATION) | Sum Insured",
            "FIRE - EXTENTIONS | Riot & Strike | Premium",
            "FIRE - EXTENTIONS | Riot & Strike | Sum Insured",
            "FIRE - EXTENTIONS | SASRIA - Fire | Premium",
            "FIRE - EXTENTIONS | SASRIA - Fire | Sum Insured",
            "FIRE - EXTENTIONS | SASRIA - Fire Escalation | Premium",
            "FIRE - EXTENTIONS | SASRIA - Fire Escalation | Sum Insured",
            "FIRE - EXTENTIONS | SASRIA - Fire Inflation  | Premium",
            "FIRE - EXTENTIONS | SASRIA - Fire Inflation  | Sum Insured",
            "FIRE - EXTENTIONS | Subsidence and Landslip | Premium",
            "FIRE - EXTENTIONS | Subsidence and Landslip | Sum Insured",
            "FIRE - EXTENTIONS | Wild Baboons and Monkeys (Buildings) | Premium",
            "FIRE - EXTENTIONS | Wild Baboons and Monkeys (Buildings) | Sum Insured",
            "FIRE - EXTENTIONS | Wild Baboons and Monkeys (Contents) | Premium",
            "FIRE - EXTENTIONS | Wild Baboons and Monkeys (Contents) | Sum Insured"
        ],
        motor: [
            "MOTOR - EFFECTIVE DATE",
            "MOTOR - MANUFACTURER",
            "MOTOR - MODEL",
            "MOTOR - YEAR",
            "MOTOR - REGISTRATION NUMBER",
            "MOTOR - MISCELLANEOUS EXTRAS INCLUDED",
            "MOTOR - VIN/CHASSIS NO",
            "MOTOR - ENGINE NO",
            "MOTOR - COVER",
            "MOTOR - IMPORTED MODIFIED VEHICLE",
            "MOTOR - CLASS OF USE",
            "MOTOR - NO YEARS CLAIM FREE",
            "MOTOR - REGISTERED OWNER",
            "MOTOR - DRIVER NAME",
            "MOTOR - MAJOR COVERS | Vehicle | Sum Insured",
            "MOTOR - MAJOR COVERS | Vehicle | Base Premium ",
            "MOTOR - MAJOR COVERS | Tracking Device",
            "MOTOR - MAJOR COVERS | Vehicle Source Selection",
            "MOTOR - MAJOR COVERS | Type Of Vehicle",
            "MOTOR - MAJOR COVERS | Basis of Settlement",
            "MOTOR - EXTENTIONS | Car Hire|Premium",
            "MOTOR - EXTENTIONS | Car Hire|Sum Insured",
            "MOTOR - EXTENTIONS | Commercial Windscreen|Premium",
            "MOTOR - EXTENTIONS | Commercial Windscreen|Sum Insured",
            "MOTOR - EXTENTIONS | Contingent Liability|Premium",
            "MOTOR - EXTENTIONS | Contingent Liability|Sum Insured",
            "MOTOR - EXTENTIONS | Credit Shortfall|Premium",
            "MOTOR - EXTENTIONS | Credit Shortfall|Sum Insured",
            "MOTOR - EXTENTIONS | Excess Waiver|Premium",
            "MOTOR - EXTENTIONS | Excess Waiver|Sum Insured",
            "MOTOR - EXTENTIONS | Fire & Explosion|Premium",
            "MOTOR - EXTENTIONS | Fire & Explosion|Sum Insured",
            "MOTOR - EXTENTIONS | Loss of Keys|Premium",
            "MOTOR - EXTENTIONS | Loss of Keys|Sum Insured",
            "MOTOR - EXTENTIONS | Loss Of Use|Premium",
            "MOTOR - EXTENTIONS | Loss Of Use|Sum Insured",
            "MOTOR - EXTENTIONS | Parking Facilities|Premium",
            "MOTOR - EXTENTIONS | Parking Facilities|Sum Insured",
            "MOTOR - EXTENTIONS | Passenger liability|Premium",
            "MOTOR - EXTENTIONS | Passenger liability|Sum Insured",
            "MOTOR - EXTENTIONS | Riot & Strike|Premium",
            "MOTOR - EXTENTIONS | Riot & Strike|Sum Insured",
            "MOTOR - EXTENTIONS | Roadside Assist|Premium",
            "MOTOR - EXTENTIONS | Roadside Assist|Sum Insured",
            "MOTOR - EXTENTIONS | Theft of Car Radio|Premium",
            "MOTOR - EXTENTIONS | Theft of Car Radio|Sum Insured",
            "MOTOR - EXTENTIONS | Third Party Liability|Premium",
            "MOTOR - EXTENTIONS | Third Party Liability|Sum Insured",
            "MOTOR - EXTENTIONS | Unauthorized Passenger Liability|Premium",
            "MOTOR - EXTENTIONS | Unauthorized Passenger Liability|Sum Insured",
            "MOTOR - EXTENTIONS | Waiver of basic compulsory first amount payable|Premium",
            "MOTOR - EXTENTIONS | Waiver of basic compulsory first amount payable|Sum Insured",
            "MOTOR - EXTENTIONS | Wreckage Removal|Premium",
            "MOTOR - EXTENTIONS | Wreckage Removal|Sum Insured"
        ]
    };

    private policyIndex = 0;
    private fireIndex = 0;
    private motorIndex = 0;
    private magicTextIndex = 0
    public loaderText = 'Loading...';
    public magicText = 'Loading Up The Unicorn...'
    public magicTextArray = [
        "Gearing up the digital engines...",
        "Document dive in progress...",
        "Converting pages into digital marvels...",
        "Weaving the document magic...",
        "Pages turning into pixels...",
        "Harnessing the power of data...",
        "Cooking up some data delights...",
        "Spinning the digital web...",
        "Unraveling the document mystery...",
        "Bringing pages to life...",
        "Transforming paper into pixels...",
        "Stirring the data cauldron...",
        "Digitizing the document landscape...",
        "Crafting a digital masterpiece...",
        "Shaping data into something spectacular...",
        "Loading the document juju...",
        "Creating a digital symphony...",
        "Infusing documents with digital stardust...",
        "Forging a path through the digital realm...",
        "Assembling data puzzle pieces...",
        "Building the digital skyscraper...",
        "Painting the digital canvas...",
        "Evolving the data ecosystem...",
        "Navigating the digital sea...",
        "Harvesting the digital fields...",
        "Constructing a data masterpiece...",
        "Architecting the digital future...",
        "Blazing the data trails...",
        "Charting the digital course...",
        "Sculpting data into works of art...",
        "Exploring the data universe...",
        "Redefining the digital landscape...",
        "Fusing data with creativity...",
        "Forging the data frontier...",
        "Embarking on a digital odyssey...",
        "Mapping the data terrain...",
        "Unleashing the data potential...",
        "Pioneering the data journey...",
        "Navigating the data labyrinth...",
        "Constructing digital dreams...",
        "Engineering the data revolution...",
        "Crafting the data symphony...",
        "Harmonizing data and innovation...",
        "Unearthing digital treasures...",
        "Energizing the digital realm...",
        "Unlocking the data vault...",
        "Transforming data into insights...",
        "Venturing into the digital unknown...",
        "Sculpting the data landscape..."
      ];
    constructor() {
        this.startLoading();
    }

    private async startLoading() {
        while (true) {
            await this.getNextData();
        }
    }

    private async getNextData() {
        this.magicText = this.magicTextArray[this.magicTextIndex]
        this.magicTextIndex = (this.magicTextIndex + 1) % this.magicTextArray.length;

        this.loaderText = this.loadingText.policy[this.policyIndex];
        this.policyIndex = (this.policyIndex + 1) % this.loadingText.policy.length;

        
        this.loaderText = this.loadingText.policy[this.policyIndex];
        this.policyIndex = (this.policyIndex + 1) % this.loadingText.policy.length;
        await this.delay(500);

        this.loaderText = this.loadingText.fire[this.fireIndex];
        this.fireIndex = (this.fireIndex + 1) % this.loadingText.fire.length;
        await this.delay(500);

        this.loaderText = this.loadingText.motor[this.motorIndex];
        this.motorIndex = (this.motorIndex + 1) % this.loadingText.motor.length;
        await this.delay(500);
    }

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
