import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged } from "firebase/auth";
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor() { }

  public user: any
  public token: string = ''

  async canActivate(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const auth = getAuth()
        await onAuthStateChanged(auth, async (user) => {
          if (user) {
            this.token = await user.getIdToken()
            this.user = user
            resolve(true)
          } else {
            alert('Sorry Please Login First')
            reject(false)
          }
        })
      } catch (error) {
        reject(false)
      }
    })
  }

  async getToken(): Promise<any> {
    return this.token
  }
}
