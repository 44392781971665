<app-top-bar />
@if(loading){
<div class="loader">
    <p>
        {{loadingService.magicText}}
        <br>
    </p>
    <h3> {{loadingService.loaderText}} </h3>
    <app-loader />
    <div class="close-loader">
        <button class="btn btn-outline-primary" (click)="closeLoader()">Close Loader</button>
    </div>
</div>
}

<div class="content">
    <div class="row">
        <div class="col">
            <button (click)="logout()" class="logout">Logout</button>
            <h4>PDF Input</h4>
            <!-- <input type="file" (change)="onFileSelected($event)" accept="application/pdf"> -->

            @if(pdfSrc){
            <iframe [src]="pdfSrc"></iframe>
            }
            @if(!pdfSrc){
            <button type="button" class="btn btn-outline-primary" (click)="selectFile()">Select File</button>
            }
        </div>
        <div class="col">
            <h4>Data Output</h4>
            <div class="outputData">
                <div ngbAccordion [closeOthers]="true">
                    @for (item of items; track item) {
                    <div ngbAccordionItem [collapsed]="item !== 'First'">
                        <h4 ngbAccordionHeader>
                            <button ngbAccordionButton>{{ item }}</button>
                        </h4>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <ngx-json-viewer [json]="doc[item]" />
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <br>
            @if(pdfSrc){
            <button type="button" class="btn btn-outline-danger bottom-btn" (click)="approveFile()">Reset</button>
            <button type="button" class="btn btn-outline-warning bottom-btn" (click)="downloadJSON()">Download
                JSON</button>
            }
        </div>
    </div>
</div>