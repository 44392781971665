<div class="content">
    <div class="background">
        <div class="shape"></div>
        <div class="shape"></div>
    </div>
    <form>
        <h3>
            Hollard Policy Reader
            <br>
            Login
        </h3>

        <label for="username">Username</label>
        <input type="text" placeholder="Email or Phone"  #usernameInput>

        <label for="password">Password</label>
        <input type="password" placeholder="Password" #passwordInput>

        <button class="login" (click)="login(usernameInput.value, passwordInput.value)">Log In</button>
        <!-- <button class="login" (click)="loginWithMicrosoft()">Login With Microsoft</button> -->
        
    </form>
</div>