import { Routes } from '@angular/router';
import { LoginComponent } from './pages/landing/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';

export const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        title: "FinTechMicro - Login"
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        title: "Dashboard",
        canActivate: [AuthGuardService],
    }
];
