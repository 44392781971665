export const environment = {
    // firebase: 'https://api.fintechmicro.com/commercialInsurancePolicy',
    //firebase: 'http://localhost:5000/fintechmicro-471ee/europe-west1',
    firebase: 'https://commercialinsurancepolicy-6twguqzsuq-ew.a.run.app',
    firebaseConfig :{
        apiKey: "AIzaSyDCA7e9lMUIxpAnNGfU1MZw_qrekbdlulM",
        authDomain: "fintechmicro-471ee.firebaseapp.com",
        projectId: "fintechmicro-471ee",
        storageBucket: "fintechmicro-471ee.appspot.com",
        messagingSenderId: "201948826533",
        appId: "1:201948826533:web:1100c3dbdbf0762968b64c"
    }
}
