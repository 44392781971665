import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environment/env';
import { AuthGuardService } from './auth-guard/auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class ExtractService {

  constructor(
    private http: HttpClient,
    private auth: AuthGuardService
  ) { }

  async getData(file: File): Promise<any> {
    const base64String = await this.convertToBase64(file)
    
    const url = environment.firebase
    const body = {
      base64PDF: base64String,
      correlationId: file.name,
      customerId: `Hollard-Demo-${this.auth.user.email}`,
      processAsync: false,
    }

    let response

    const token = await this.auth.getToken()
    const authToken = `Bearer ${token}`

    // Set headers
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', authToken);

    try {
      response = await this.http.post(url, body, { headers }).toPromise();
    } catch (error) {
      console.log(error)
      alert('Seems Like We Have An Issue, Please try again')
      console.warn(error)
      response = { error: error }
    }

    return response
  }


  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(',')[1] || '');
      reader.onerror = (error) => reject(error);
    });
  }

}
