import { Component } from '@angular/core';
import { TopBarComponent } from '../../components/top-bar/top-bar.component';
import { DomSanitizer } from "@angular/platform-browser";
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from '../../services/loadingText';
import { LoaderComponent } from '../../components/loader/loader.component';
import { sampleData } from '../../services/sampleData';
import { ExtractService } from '../../services/extract.service';
import { getAuth } from 'firebase/auth';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    TopBarComponent,
    LoaderComponent,
    NgxJsonViewerModule,
    NgbAccordionModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  constructor(
    private sanitizer: DomSanitizer,
    public loadingService: LoadingService,
    private extract: ExtractService
  ) {

  }

  pdfSrc: any;
  loading = false

  items: any = [];

  doc: any = {
    pendingUpload: {
      "status": "pending file upload",
      "description": "Please Upload a file to process data"
    }
  }


  async getData(file: File) {
    this.loading = true
    const result = await this.extract.getData(file)
    console.log(result)
    this.items = [];
    for (let x in result) {
      this.items.push(x)
      //@ts-ignore
      this.doc[x] = result[x]
    }

    this.loading = false
  }

  logout() {
    const auth = getAuth()
    auth.signOut();
  }

  approveFile() {
    // alert('File Processed To Main System');
    this.items = ['sections'];
    this.pdfSrc = undefined
    this.loading = false
  }

  selectFile() {

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'application/pdf';
    return new Promise((resolve, reject) => {
      let focusHandler: any;
      fileInput.onchange = async (event: any) => {
        const file = event.target.files[0];
        if (file.type === 'application/pdf') {
          const reader = new FileReader();
          reader.onload = () => {
            const _data = reader.result;
            this.getData(file)
            //@ts-ignore
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(_data);
          };
          reader.readAsDataURL(file);
        } else {
          alert('Please select a valid PDF file.');
        }
      }

      fileInput.click();

      focusHandler = () => {
        setTimeout(() => { // Set timeout to ensure the focus event is finished before checking
          if (!fileInput.value) { // If no file was selected
            this.loading = false;
            reject(new Error('No file was selected')); // or resolve with a custom object if needed
          }
        }, 100);
      };

      window.addEventListener('focus', focusHandler);

    });

  }

  downloadJSON() {
    // Step 1: Convert the JSON object to a string
    const jsonStr = JSON.stringify(this.doc, null, 2); // null and 2 for pretty-printing

    // Step 2: Create a Blob from the JSON string
    const blob = new Blob([jsonStr], { type: 'application/json' });

    // Step 3: Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Step 4: Create a link element and set the download attribute
    const link = document.createElement('a');
    link.href = url;
    link.download = 'export.json'; // Desired filename

    // Step 5: Programmatically click the link to trigger the download
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  }

  closeLoader() {
    this.loading = false
  }

  jsonData: any;
  intervalId: any;

}
